import React, { useRef, useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";

const UpdateModal = React.forwardRef((props, ref) => {
	// const { t } = useTranslation();
	const [snackOpen, setSnackOpen] = useState(false);
	const timers = useRef([]);
	const swWaiting = useRef(false);
	const registration = useRef(null);
	const newWorker = useRef(null);
	const reloading = useRef(false);

	console.log(
		"UpdateModal starting, cloud " + props.cloud + " swWaiting " + swWaiting.current
	);

	const openSnack = () => {
		setSnackOpen(true);
	};
	const closeSnack = () => {
		setSnackOpen(false);
	};
	const updateNow = () => {
		// console.log("Client requested update NOW !!!");
		if (registration.current.waiting)
			registration.current.waiting.postMessage({ type: "SKIP_WAITING" });
		else console.log("lost touch with waiting SW!!!");
		clearTimers(props.cloud);
		setSnackOpen(false);
	};

	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	useEffect(() => {
		// console.log("UpdateModal cloud " + props.cloud); // log on change
	}, [props.cloud]);

	useEffect(() => {
		async function getRegistration() {
			return new Promise(async (resolve) => {
				let delay = 125;
				while (registration.current == null) {
					await sleep(delay);
					let r = await navigator.serviceWorker.getRegistration();
					// until registration has been done, a null is returned
					if (r) {
						resolve(r);
						return;
					}
					if (delay < 3600000) delay = delay * 2; // max 1 hour
				}
			});
		}
		let monitorNewSw = async (sw) => {
			// console.log("monitoring new sw");
			newWorker.current = sw;
			newWorker.current.onstatechange = async (event) => {
				let state = newWorker.current.state;
				// console.log("update, new sw state now " + state);
				if (state === "installed") {
					handleSwWaiting();
				} else if (state === "activated") {
					// due to user choosing "Update Now"
					console.log("update, activated detected, RELOAD!");
					await sleep(4000);
					window.location.reload();
				}
			};
			if (registration.current.waiting) handleSwWaiting();
		};
		let handleSwWaiting = async () => {
			// console.log("handle sw waiting");
			if (navigator.serviceWorker.controller) {
				swWaiting.current = true;
				console.log("a new sw is awaiting");
				openSnack();
			}
		};
		let manageUpdates = async () => {
			// let reg = await navigator.serviceWorker.ready; // get the registration
			// registration.current = reg;
			registration.current = await getRegistration();
			// console.log("starting registration monitoring");
			// console.log(registration.current);
			// console.log(
			// 	registration.current.installing?.state +
			// 		" " +
			// 		registration.current.waiting?.state +
			// 		" " +
			// 		registration.current.active?.state
			// );
			if (registration.current.active) {
				// one sw is already active
				if (registration.current.installing) {
					monitorNewSw(registration.current.installing);
					return;
				} else if (registration.current.waiting) {
					handleSwWaiting();
					return;
				}
			}
			registration.current.onupdatefound = (event) => {
				// console.log("registration update found, event:");
				// console.log(event);
				// console.log(
				// 	registration.current.installing?.state +
				// 		" " +
				// 		registration.current.waiting?.state +
				// 		" " +
				// 		registration.current.active?.state
				// );
				if (registration.current.active) {
					if (registration.current.installing) {
						monitorNewSw(registration.current.installing);
						return;
					} else if (registration.current.waiting) {
						handleSwWaiting();
						return;
					}
				}
				newWorker.current = registration.current.installing;
				newWorker.onstatechange = async (event) => {
					let state = newWorker.state;
					// console.log("onstatechange, new sw state now " + state);
					// console.log(event);
					// console.log(
					// 	registration.current.installing?.state +
					// 		" " +
					// 		registration.current.waiting?.state +
					// 		" " +
					// 		registration.current.active?.state
					// );
					if (registration.current.waiting && registration.current.active) {
						handleSwWaiting();
						return;
					} else if (registration.current.installing) {
						monitorNewSw(registration.current.installing);
						return;
					}
					if (state === "activated") {
						// newSW is the first sw
						// console.log("restart monitoring of registration? ");
						registration.current.onupdatefound = (event) => {
							registration.current = event.target;
							// console.log("registration onupdatefound 2, event:");
							// console.log(event);
							// console.log(
							// 	registration.current.installing?.state +
							// 		" " +
							// 		registration.current.waiting?.state +
							// 		" " +
							// 		registration.current.active?.state
							// );
							if (registration.current.waiting) {
								handleSwWaiting();
								return;
							} else if (registration.current.installing) {
								monitorNewSw(registration.current.installing);
								return;
							}
						};
					}
				};
			};
		};
		manageUpdates();

		navigator.serviceWorker.addEventListener("controllerchange", (e) => {
			// console.log("The controller of current browsing context has changed.");
			if (!registration.current) {
				console.log("no registration yet for controllerchange event");
				console.log(e);
				return;
			}
			// console.log(
			// 	registration.current.installing?.state +
			// 		" " +
			// 		registration.current.waiting?.state +
			// 		" " +
			// 		registration.current.active?.state
			// );
			// console.log(e.target);
			if (
				registration.current.installing ||
				registration.current.waiting ||
				registration.current.active
			)
				if (!reloading.current) {
					reloading.current = true;
					console.log("RELOADING NOW!!!!");
					// window.location.reload();	//TODO: figure how how to know we need to reload
				}
		});
	}, []);

	function clearTimers(arg) {
		// console.log("clearing timers, cloud " + props.cloud + " arg " + arg);
		while (timers.current.length > 0) {
			let clearing = timers.current.pop();
			if (clearing) clearTimeout(clearing);
		}
	}

	// console.log("#timers " + timers.current.length);
	clearTimers(props.cloud);
	// console.log("after clear " + timers.current.length);

	function pingUpdate() {
		let appOnline = props.cloud;
		// console.log("pingUpdate " + cloudy);
		if (
			registration.current &&
			!swWaiting.current &&
			window.navigator.onLine &&
			appOnline
		) {
			console.log("check for update... " + appOnline);
			registration.current.update();
		}
	}

	function pingUser() {
		let appOnline = props.cloud;
		if (
			registration.current &&
			swWaiting.current &&
			window.navigator.onLine &&
			appOnline
		) {
			console.log("pingUser...");
			setSnackOpen(true);
		}
	}

	// console.log("starting timer " + props.cloud);
	if (!swWaiting.current) {
		timers.current.push(setInterval(pingUpdate, 30000)); // 5 minutes
	} else {
		timers.current.push(setInterval(pingUser, 120000)); // one hour
	}

	const upgradeAction = (
		<React.Fragment>
			<Button color="secondary" size="medium" onClick={updateNow}>
				Update Now
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={closeSnack}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<Snackbar
			open={snackOpen}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			onClose={closeSnack}
			message="New software is available "
			key={"snackbar"}
			autoHideDuration={15000}
			TransitionComponent={Fade}
			ContentProps={{
				sx: {
					bgcolor: "#ceeefe",
					fontWeight: "bold",
					color: "primary.dark"
				}
			}}
			action={upgradeAction}
		></Snackbar>
	);
});

export default UpdateModal;
