function setupTooltipAdjustments() {
	// from stackoverflow by Takit Isy
	var tooltips = document.querySelectorAll(".sfm-footnote");
	tooltips.forEach(function (tooltip, index) {
		// For each tooltip
		tooltip.addEventListener("mouseover", position_tooltip); // On hover, launch function below
	});
}

function position_tooltip(e) {
	// mouseover (hover) handler
	// console.log("hover");
	// console.log(e);
	var width = window.innerWidth;

	// Get calculated tooltip coordinates and size
	var ref_rect = this.getBoundingClientRect();
	var tooltiptext = this.parentNode.querySelector(".tooltiptext");
	var tt_rect = tooltiptext.getBoundingClientRect();

	var farRight = width - (ref_rect.right + tt_rect.width) < ref_rect.width;
	var tooHigh = ref_rect.top - tt_rect.height < 2 * ref_rect.height;
	var targetX = farRight
		? -Math.min(tt_rect.width - ref_rect.width, ref_rect.left)
		: 0;
	var targetY = tooHigh ? ref_rect.height : -tt_rect.height;
	// Position tooltip RELATIVE TO THE REFERENCE RECTANGLE
	tooltiptext.style.left = targetX + "px";
	tooltiptext.style.top = targetY + "px";
}

export default setupTooltipAdjustments;
