import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import metadata from "../metadata.json";

const UserGuide = React.forwardRef((props, ref) => {
	const helpStyle = {
		position: "absolute",
		top: "10%",
		left: "10%",
		transform: "translate(-5%, -5%)",
		maxHeight: "90%",
		overflow: "scroll",
		bgcolor: "background.paper",
		border: "1px solid #000",
		borderRadius: "16px",
		boxShadow: 24,
		p: 4
	};
	const v = metadata.version;
	const { t } = useTranslation();
	const vLabel = t("Version") + " " + v.major + "." + v.minor + "." + v.revision;
	const defaultParts = [
		"UserGuide",
		"Navigation",
		"Settings",
		"WebCatalog",
		"Downloading",
		"Local",
		"Installation"
	];
	const parts = props.helpItem || defaultParts;
	function getFormatting(tag, other) {
		const jsonStr = t(tag);
		console.log(jsonStr);
		if (jsonStr.endsWith("SX")) return other;
		const json = JSON.parse(jsonStr);
		const result = { ...other, ...json };
		console.log(result);
		return { ...other, ...json };
	}

	return (
		<Box sx={helpStyle}>
			<Typography variant="body" sx={{ mt: 0.5 }}>
				{vLabel}
			</Typography>

			{parts.map((part, index) => (
				<Box key={"help" + index}>
					<Typography
						variant="title"
						component="h3"
						sx={getFormatting(part + "HeadingSX", { mt: 1 })}
					>
						{t(part + "Heading")}
					</Typography>
					<Typography variant="body" sx={{ mt: 0.5 }}>
						{t(part + "Text")}
					</Typography>
				</Box>
			))}
		</Box>
	);
});

export default UserGuide;
