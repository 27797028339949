import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Slide from "@mui/material/Slide";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import HideOnScroll from "./components/HideOnScroll";
import HlmdToolbar from "./components/HlmdToolbar";
import Home from "./components/Home";
import WebCatalog from "./components/WebCatalog";
import Local from "./components/Local";
import UserGuide from "./components/UserGuide";
import ItemPresenter from "./components/ItemPresenter";
import withSplashScreen from "./components/withSplashScreen";
import MobileSwipe from "./components/MobileSwipe";
import LoginDialog from "./components/LoginDialog";
import * as recent from "./utilities/recent";
import * as safe from "./utilities/safe";
import * as db from "./utilities/database";
import * as io from "./utilities/io";
import { downloadFiles } from "./utilities/download";
import { dateToString } from "./utilities/dateFormatter";
import UpdateModal from "./components/UpdateModal";
import { handleMatomoPageview } from "./utilities/bookkeeping";
import { toSimulatedURL } from "./utilities/utilities";
import { useHistory } from "./context/HistoryContext";

const verbose = false;

const beta = true;
const betaProps = { beta: beta };
const AppPage = {
	home: 0,
	webcat: 1,
	local: 2,
	presenter: 3,
	login: 4
};
const pageNames = ["Home", "WebCatalog", "LocalCatalog", "Presenter", "Login"];
const AppSetting = {
	language: 0,
	fonts: 1,
	appOffline: 2,
	clearRecent: 3,
	clearAll: 4,
	account: 5,
	serverMode: 6
};

function App(props) {
	//
	// withSplash does 1st stage of launch, reading from LocalStorage
	// information to decide the type of login (if any) to do, and
	// to determine if this is the first login (no data stored)
	//
	// console.log(props);

	const startWithLogin = props.loginType !== 0 && props.loginType !== 3;
	const tid = props.tid;
	const startWithLink = tid && tid.length === 5 ? true : false; // true if loginType = 3
	const startPage = startWithLogin
		? AppPage.login
		: startWithLink
			? AppPage.webcat
			: AppPage.home;
	const initialStateProps = startWithLink ? { tid: tid } : {};

	// console.log(
	// 	"App props: startWith login? " +
	// 		startWithLogin +
	// 		", link? " +
	// 		startWithLink +
	// 		", page " +
	// 		startPage
	// );

	const [page, setPage] = useState(startPage);
	const [nextPage, setNextPage] = useState(0);
	// const [lastPage, setLastPage] = useState(0);
	// const [backStack, setBackStack] = useState([]); // stack of 'back' handlers, one consumed per 'back' click
	const [showWeb, setShowWeb] = useState(startWithLink); // no showHome, it shows if nothing else is showing
	const [showLocal, setShowLocal] = useState(false);
	const [showPresenter, setShowPresenter] = useState(false);
	const [showLogin, setShowLogin] = useState(startWithLogin);
	// const [loginError, setLoginError] = useState(false);
	const [viewRecent, setViewRecent] = useState(true); // quick removal of visible viewing recent while deleting
	const [lReady, setLReady] = useState(!startWithLogin);
	const [ready, setReady] = useState(false); // crypto (everything) ready
	const [helpOpen, setHelpOpen] = useState(false);
	const [helpItem, setHelpItem] = useState(null);
	const [snackShowing, setSnackShowing] = useState(false);
	const loginData = useRef({ o: 4, d: "" }); // options === 4 is starting state only
	const loginProps = useRef({ firstLogin: props.loginType === 1 ? true : false });
	const cryptoLock = useRef(0);
	const [appOffline, setAppOffline] = useState(false);
	const [netOffline, setNetOffline] = useState(!window.navigator.onLine);
	const [serverMode, setServerMode] = useState(-1);
	const msgChannel = useRef(null);
	const { t } = useTranslation();
	const errors = useRef("App startWithLogin " + startWithLogin);
	const popListenerReady = useRef(false);
	const nowPage = useRef(0);
	const history = useHistory();
	const navType = useRef(0);

	nowPage.current = page; // hack to deal with closures

	// for Menu/App bar (not all possible pages)
	const pages = [t("HomePage"), t("WebCatalog"), t("LocalCatalog")];
	const serverModes = useMemo(() => [t("OriginOnly"), t("AlternatesPreferred")], [t]);
	const serverModeCommands = ["oo", "ap"];

	// for navigation support
	const allShows = useRef([
		null,
		setShowWeb,
		setShowLocal,
		setShowPresenter,
		setShowLogin
	]);
	const setShow = useCallback((i, value) => {
		if (i === 0) return;
		let controller = allShows.current[i];
		controller(value);
	}, []);
	const stateProps = useRef(startWithLogin ? {} : initialStateProps);
	const nextStateProps = useRef(startWithLogin ? initialStateProps : {});

	// console.log("App rendering; lReady " + lReady + ", page " + page);
	// logPages();
	// console.log(history.report());
	// console.log(window.location.search);
	errors.current += "\n href " + window.location.href;
	if (props.loginType === 3) loginData.current = { o: 0, d: "" }; // auto-login TODO: move to a useEffect???

	const handlePageSelect = (page) => {
		handlePageChange(page, 0);
	};

	const handlePageChange = useCallback(
		(next, type) => {
			let p = nowPage; // stable reference (can't use page, it is captured in closure)
			// console.log(
			// 	`handlePageChange from page ${p.current} to page ${next}, type ${type}`
			// );
			// console.log(JSON.stringify(nextStateProps.current));

			// orchestrates what view is shown / not shown
			// sometimes 2 calls to allow exit transitions
			if (!(next >= 0 && next < allShows.current.length)) return; // bad argument
			if (next === p.current) {
				if (next === AppPage.webcat || next === AppPage.local) {
					// internal page navigation, appear to slide out and in again
					navType.current = type;
					setShow(p.current, false); // slide out current view
					setNextPage(next); // slide in new view
				}
				return;
			} else {
				// if (type !== 0) console.log("traversing history");
				if (p.current !== AppPage.login)
					// capture scrollY one last time on page change
					history.replaceState({ ...history.state(), s: window.scrollY });
				if (p.current === AppPage.home) {
					// if currently on Home, transition immediately to non-default page
					setPage(next);
					// console.log("leaving page" + p.current);
					if (p.current !== AppPage.login) {
						if (type === 0) history.pushState({ p: next });
						else if (type === -1) history.pop();
						else if (type === 1) history.go();
						else console.warn("illegal page change type " + type);
					}
					if (next === AppPage.presenter)
						stateProps.current = { ...nextStateProps.current };
					setShow(next, true);
				} else {
					// transition away from other page first
					// add history ahead of showing component
					navType.current = type;
					setShow(p.current, false);
					setNextPage(next); // where to go on slide-out complete
				}
				// console.log(history.report());

				handleMatomoPageview(pageNames[next], pageNames[next]);
			}
		},
		[history, setShow]
	);
	const handleOpenHelp = (select) => {
		if (!select && stateProps.current) {
			const node = stateProps.current.node;
			if (node && node.mediaType && node.mediaType) {
				if (node.mediaType === "sfm" || node.mediaType === "usx")
					select = ["Sfm", "SfmAudio", "SfmMore"];
			}
		}
		setHelpItem(select);
		setHelpOpen(true);
	};
	const handleCloseHelp = (event) => {
		setHelpOpen(false);
	};
	function handleExited(oldPage) {
		// when child page exits due to user press or page change
		window.scrollTo(0, 0); // reset any div scroll left by previous page
		stateProps.current = { ...nextStateProps.current };
		// console.log("handle page exited, stateProps set to");
		// console.log(JSON.stringify(stateProps.current));
		setPage(nextPage);
		if (nextPage === AppPage.home) setViewRecent(true); //reset from any 'clear recent items'
		if (oldPage !== AppPage.login) {
			if (navType.current === 0) history.pushState({ p: nextPage });
			else if (navType.current === -1) history.pop();
			else if (navType.current === 1) history.go();
		}
		navType.current = 0;
		// console.log("handleExited setting show " + pageNames[nextPage]);
		setShow(nextPage, true);
	}

	const handleBack = useCallback(() => {
		// console.log("handleBack");
		let p = history.state().p;
		if (p === AppPage.home)
			history.replaceState({ ...history.state(), s: window.scrollY });
		let thisState = history.pop(); // remove state
		let newPage = history.state().p; // get next page
		history.pushState(thisState); // replace state
		nextStateProps.current = {}; // erase old props
		handlePageChange(newPage, -1);
	}, [handlePageChange, history]);

	function nodeSelected(node) {
		// console.log("node selected callback, " + node.path);
		// console.log(JSON.stringify(node));
		logPages();
		handleMatomoPageview(
			toSimulatedURL(node.path),
			node.name + " (" + node.mediaType + ")"
		);
		nextStateProps.current = { node: node };
		handlePageChange(AppPage.presenter, 0); // start the presenter (with transitions out+in)
	}

	function onDone(node) {
		// handle a 'done' event from a presenter
		if (!node)
			handlePageSelect(0); // probaby an error, just go to Home
		else if (node.contents) {
			nextStateProps.current = { id: node.id };
			handlePageChange(AppPage.webcat, 0); // launch catalog, viewing a folder
		} else {
			console.warning("App invalide call to onDone");
			handlePageSelect(0);
		}
	}
	const getSettings = () => {
		let onoff = appOffline ? "Online" : "Offline";
		return [
			{ label: t("Language"), menu: ["English", "中文"] },
			{ label: t("FontSize") },
			{ label: t(onoff) },
			{ label: t("ClearRecent"), disabled: !viewRecent },
			{ label: t("ClearAll") },
			{ label: t("Account") },
			{
				label: t("Servers"),
				menu: serverModes,
				menuSelected: serverMode
			}
		];
	};
	const languageAbbreviations = ["en", "cn"];
	function handleSetting(index, subindex) {
		// process setting from Toolbar
		// console.log("handle setting " + index + " " + subindex);
		switch (index) {
			case AppSetting.language:
				if (subindex >= 0)
					i18next.changeLanguage(languageAbbreviations[subindex]);
				break;
			case AppSetting.clearRecent:
				if (page === AppPage.home) setViewRecent(false); // force a repaint if showing recent
				if (recent) recent.clear();
				break;
			case AppSetting.clearAll:
				clearAll();
				break;
			case AppSetting.appOffline:
				toggleAppOffline();
				break;
			case AppSetting.account:
				console.log("setting up AppSetting.account");
				loginProps.current = { editLogin: true };
				cryptoLock.current = -2;
				setLReady(false); // will set to true when done
				setShowLogin(true);
				break;
			case AppSetting.serverMode:
				if (subindex >= 0) changeServerMode(subindex);
				break;
			default:
				break;
		}
	}
	function handleNetChange(status) {
		//console.log("handleNetChange " + status); // online true/false
		if (netOffline && page !== AppPage.webcat) setTimeout(downloadFiles, 4000);
		setNetOffline(!status); // offline reversed
	}
	function toggleAppOffline() {
		const swAlive = navigator.serviceWorker && navigator.serviceWorker.controller;
		if (swAlive && msgChannel) {
			const cmd = appOffline ? "up" : "down";
			navigator.serviceWorker.controller.postMessage({ command: cmd });
		}
		if (appOffline && page !== AppPage.webcat) setTimeout(downloadFiles, 4000);
		console.log("setting appOffline to " + !appOffline);
		setAppOffline(!appOffline);
	}
	function changeServerMode(newMode) {
		// newMode is enum
		const swAlive = navigator.serviceWorker && navigator.serviceWorker.controller;
		if (swAlive && msgChannel) {
			navigator.serviceWorker.controller.postMessage({
				command: serverModeCommands[newMode]
			});
			console.log("setting serverMode to " + serverModeCommands[newMode]);
			setServerMode(newMode);
		}
	}
	const resetDB = async () => {
		// if localStorage was flushed, ability to create/use keys is gone
		await db.clearDB("cache"); // so need to delete all encrypted data
		await db.clearDB("itemCache");
	};
	async function clearAll() {
		await resetDB();
		let storage = window["localStorage"];
		if (storage) storage.clear();
		if (page === AppPage.home) setViewRecent(false);
		if (recent) recent.clear();
		//navigator.serviceWorker.controller.postMessage({ command: "clearAll" });
		loginData.current = { o: 0, d: "" }; // prep to reset crypto
		setLReady(false);
		await new Promise((resolve) => {
			setTimeout(() => {
				cryptoLock.current = -2; // enable crypto reinit
				setLReady(true);
			}, 100);
		});
	}
	const handleLoginClose = (value1, value2) => {
		// capture values from Login modal, and save for use in useEffect thread
		// Login will have validated password if appropriate
		console.log("App handleLoginClose " + value1 + " " + value2);
		errors.current += "\n handleLoginClose";
		setShow(AppPage.login, false);
		if (value1 === null && value2 === null) return; // editLogin canceled
		if (loginProps.current.firstLogin || loginProps.current.editLogin) {
			// initial login or account was editted
			let options = 0;
			if (value1 !== "") {
				// password provided
				if (value2 === "weekly") {
					options = 1;
				} else if (value2 === "monthly") {
					options = 2;
				} else {
					options = 3; // always require password
				}
			}
			loginData.current = { o: options, d: value1 };
			if (loginProps.editLogin) {
				console.log("handling editLogin close");
				cryptoLock.current = -2;
				setLReady(true);
				return; // no need to handle Login exited
			} else {
				// new login or login with data partially erased
				resetDB().then(() => setLReady(true)); // login data is ready for initialization
			}
			// console.log(loginData.current);
		} else if (props.loginType === 2) {
			// subsequent login, confirm the password? not needed
			// if ((beta && safe.auth(value1)) || (!beta && safe.confirm(value1))) {
			// 	setLoginError(false);
			// 	setLReady(true); // login data is ready for initialization
			// } else {
			// 	setLoginError(true);
			// 	return; // TODO: pass error information back to login prompt
			// }
			setLReady(true);
		} else {
			console.error("ERROR invalid Login close state ***********************");
			console.log(props);
			console.log(loginProps);
			setLReady(true);
		}
		handleExited(AppPage.login);
		return;
	};
	function onSwipe(e) {
		console.log(e);
		let direction = e.deltaX < 0 ? 1 : -1;
		if (Math.abs(e.deltaX) > 2 * Math.abs(e.deltaY)) {
			history.go(direction);
			let state = history.state();
			handlePageChange(state.p, direction);
		}
	}
	function closeSnack() {
		setSnackShowing(false);
	}
	function logPages() {
		// diagnostic function
		// console.log(
		// 	page +
		// 		" " +
		// 		showWeb +
		// 		" " +
		// 		showLocal +
		// 		" " +
		// 		showPresenter +
		// 		" " +
		// 		nextPage
		// +
		// " " +
		// lastPage
		// );
		return page === 0;
	}

	useEffect(() => {
		// set up communications with service worker
		if (msgChannel.current === null) {
			// once per session
			msgChannel.current = new MessageChannel();
			msgChannel.current.port1.onmessage = function (event) {
				console.log("App messageChannel event...");
				// console.log(event);
				if (event.data.error) {
					console.log(
						"App error on messageChannel port 1 " + event.data.error
					);
				} else if (event.data.type) {
					if (event.data.type === "state") {
						let newOffline = event.data.n === "off" ? true : false;
						console.log("setting appOffline to " + newOffline);
						setAppOffline(newOffline);
						setServerMode(event.data.m);
					} else {
						console.log("Unknown message data type");
						console.log(event.data);
					}
				} else {
					console.log("Unknown sw message ");
					console.log(event.data);
				}
			};
			// Function below sends a message data as well as transferring messageChannel.port2 to the service worker.
			// The service worker can then use the transferred port to reply via postMessage(), which
			// will in turn trigger the onmessage handler on messageChannel.port1 (above).
			// See https://html.spec.whatwg.org/multipage/workers.html#dom-worker-postmessage
			// e.g.   navigator.serviceWorker.controller.postMessage({command: 'noCredit'});
			const openServiceWorkerChannel = async (callback) => {
				let serviceWorkerContainer = navigator.serviceWorker;
				serviceWorkerContainer.ready.then((registration) => {
					// ready is allowed to return when the state of the active
					// entry of swc is activating or activated
					// delay callback if not yet active (single delay for now)
					if (
						registration.active &&
						registration.active.state === "activated"
					) {
						callback(registration);
					} else {
						setTimeout(() => {
							callback(registration);
						}, 200); // allow other active work to complete first
					}
				});
			};
			openServiceWorkerChannel((registration) => {
				let sw = navigator.serviceWorker.controller || registration.active;
				if (sw.state !== "activated")
					console.log("sw not yet activated! " + sw.state);
				sw.postMessage({ type: "INIT_PORT" }, [msgChannel.current.port2]);
				sw.postMessage({ command: "getState" }); // request current, reply handled above
			});

			if (!popListenerReady.current) {
				popListenerReady.current = true;

				// console.log("before push of home, length is " + history.length());
				history.pushState({ p: AppPage.home });
				// console.log("after push length is " + history.length());
				// console.log(history.report());
				window.history.pushState(
					"{myState:starting}",
					"",
					window.location.href
				);
				window.removeEventListener("popstate", handlePopEvent);
				window.addEventListener("popstate", (event) => {
					setTimeout(() => {
						handlePopEvent(event);
					}, 0); // at end of the then current queue
				});
			}
			return () => {
				window.removeEventListener("popstate", handlePopEvent);
				popListenerReady.current = true;
			};
		}

		function handlePopEvent(event) {
			if (verbose) console.warn("browser navigation pop");
			if (verbose) console.log(event);
			// window.scrollTo(0, window.scrollY - 4); // not needed if we are doing 'back'?
			setSnackShowing(true);
			handleBack();
			window.history.pushState("{myState:continue}", "", window.location.href);
		}

		return () => {
			window.removeEventListener("popstate", handlePopEvent);
		};
	}, [handleBack, handlePageChange, history]);

	useEffect(() => {
		// prepare for immediate link launch (once only)
		if (startWithLink) history.pushState({ p: AppPage.webcat }, "");
		// if (startWithLink)
		// console.log("after link push, length is " + history.length());
	}, [history, startWithLink]);

	useEffect(() => {
		// initialize encryption, network service url mapping, authentication
		if (lReady) {
			console.log("setting up encryption ");
			const date = dateToString(new Date());
			let storage = window["localStorage"];
			storage.setItem("d", date);
			async function enableData() {
				let result = await safe.setOptions(
					loginData.current.o,
					loginData.current.d
				);
				if (!result) {
					console.log("setOptions returned not OK");
					errors.current += "\n setOptions failed ";
				}
				return result;
			}
			async function setupIO() {
				let result = await io.initializeServices().catch((error) => {
					console.error("enableData failed in useEffect");
					errors.current += "\n setOptions failed ";
					result = false;
				});
				return result;
			}
			function doAuthCheck(result) {
				const offset = beta
					? -1
					: window.location &&
						window.location.href &&
						window.location.href.indexOf("localhost");
				const authOK = offset > 0 ? true : result;
				errors.current += "\n authorized " + authOK;
				setReady(authOK); // TODO: pre-release: replace ok with true
				return authOK;
			}
			cryptoLock.current += 1;
			if (cryptoLock.current === 1)
				// first setup for this session
				enableData().then(async (result) => {
					console.log("enableData " + result);
					if (result) result = await setupIO();
					console.log("setupIO " + result);
					if (result) result = doAuthCheck(result);
					console.log("doAuthCheck " + result);
				});
			else if (cryptoLock.current === -1) {
				// processing editLogin
				cryptoLock.current = 1;
				enableData().then((result) => {
					if (result) result = doAuthCheck(result);
				});
			}
		}
	}, [lReady]);

	const containerRef = React.useRef(null);

	const theme = createTheme({
		components: {
			MuiToolbar: {
				styleOverrides: {
					dense: {
						height: 64,
						minHeight: 64,
						"@media (max-width: 780px)": {
							height: 48,
							minHeight: 48
						}
					}
				}
			}
		}
	});
	/*console.log(
		"before App return appOffline " + appOffline + " netOffline " + netOffline
	); */
	// console.log("before return:");
	// console.log(JSON.stringify(stateProps.current));
	return (
		<React.Fragment>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				{!showLogin && ready && (
					<HideOnScroll threshold={120}>
						<AppBar>
							<HlmdToolbar
								pages={pages}
								settings={getSettings()}
								cloud={!appOffline}
								hideBack={history.length() < 2}
								handleBack={handleBack}
								handlePageSelect={handlePageSelect}
								handleOpenHelp={handleOpenHelp}
								handleSetting={handleSetting}
								handleNetChange={handleNetChange}
								sx={{ width: "100%" }}
							/>
						</AppBar>
					</HideOnScroll>
				)}
				<MobileSwipe onSwipe={onSwipe}>
					<Container maxWidth="lg" ref={containerRef}>
						{ready &&
							logPages() && ( // if crypto ready and page===0
								<Home
									recent={viewRecent.toString()}
									handlePageSelect={handlePageSelect}
									selected={nodeSelected}
								/> // disable recent on 'clear recent'
							)}
						{ready && ( // slide in pages, each with own boolean 'in' state
							<>
								<Slide
									in={showWeb}
									direction="left"
									timeout={{ enter: 120, exit: 80 }}
									mountOnEnter
									unmountOnExit
									onExited={() => handleExited(AppPage.webcat)}
									container={containerRef.current}
								>
									<WebCatalog
										selected={nodeSelected}
										handleBack={handleBack}
										offline={appOffline || netOffline}
										{...stateProps.current}
									/>
								</Slide>
								<Slide
									in={showLocal}
									direction="left"
									timeout={{ enter: 120, exit: 80 }}
									mountOnEnter
									unmountOnExit
									onExited={() => handleExited(AppPage.local)}
									container={containerRef.current}
								>
									<Local
										selected={nodeSelected}
										handleBack={handleBack}
										offline={appOffline || netOffline}
									/>
								</Slide>
								<Slide
									in={showPresenter}
									direction="left"
									timeout={{ enter: 120, exit: 80 }}
									mountOnEnter
									unmountOnExit
									onExited={() => handleExited(AppPage.ItemPresenter)}
									container={containerRef.current}
								>
									<ItemPresenter
										{...stateProps.current}
										onDone={onDone}
									/>
								</Slide>
							</>
						)}
						{showLogin && ( // add only after node has valid data
							<LoginDialog
								{...betaProps}
								{...loginProps.current}
								open={showLogin}
								// error={loginError}
								onClose={handleLoginClose}
							/>
						)}
						{!showLogin &&
							!ready && ( // transient after login while encryption not ready
								<>
									<div style={{ maxWidth: "50%", padding: "20px" }}>
										<svg className="heart" viewBox="0 0 80 74">
											<path
												fill="red"
												d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,
                          						0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
                          						c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
											/>
										</svg>
										{/* <div>{errors.current}</div> */}
									</div>
								</>
							)}
						<UpdateModal cloud={!appOffline} />
					</Container>
				</MobileSwipe>
				<Modal open={helpOpen} onClose={handleCloseHelp}>
					<UserGuide helpItem={helpItem} />
				</Modal>{" "}
				<Snackbar
					open={snackShowing}
					onClose={closeSnack}
					autoHideDuration={5000}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
				>
					<Alert
						variant="outlined"
						severity="warning"
						sx={{ bgcolor: "background.paper" }}
					>
						{"Use tool bar's '<' button and not the browser's'"}
					</Alert>
				</Snackbar>
			</ThemeProvider>
		</React.Fragment>
	);
}

export default withSplashScreen(App);
