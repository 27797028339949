import React, { useRef, useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Paper from "@mui/material/Paper";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import SelectionDialog from "./SelectionDialog";
import i18next from "i18next";
//import { useTranslation } from "react-i18next";
import sfmToHtml from "./SFM/sfmParser";
import usxToHtml from "./SFM/usxParser";
import getSfmRanges from "./SFM/sfmRanges";
import * as recent from "../utilities/recent.js";
import * as database from "../utilities/database.js";
import * as io from "../utilities/io.js";
import { handleMatomoCustomEvent } from "../utilities/bookkeeping.js";
import { useHistory } from "../context/HistoryContext.js";
import setupTooltipAdjustments from "./SFM/Tooltips";
import * as syncAudio from "./SFM/syncAudio.js";
import "./SFM/SFM.css";

<link rel="manifest" href="/manifest.json"></link>;
const topOffset = 52; // App bar is 48 overall
const AudioStates = {
	noData: 0,
	initialized: 1, // book is audio capable
	ready: 2 // audio play and tracking is enabled
};
const verseRegex = /([0-9]+:)\[?([0-9]+)/;

const SfmViewer = React.forwardRef((props, ref) => {
	const [versePopupOpen, setVersePopupOpen] = useState(false);
	const [verse, setVerse] = useState("1:1");
	const [data, setData] = useState("<span class='loader'></span>");
	const [dataReady, setDataReady] = useState(false);
	const [audioState, setAudioState] = useState(AudioStates.noData);
	const [audioShowing, setAudioShowing] = useState(false);
	const [audioURL, setAudioURL] = useState(null);
	const history = useHistory();

	let nodeRef = useRef(props.node);
	let initialAudio = useRef(history.state().audio === AudioStates.ready);
	let timings = useRef(null);
	let anchors = useRef(null);
	let jumpToRef = useRef(null);
	let jumpToTop = useRef(0);
	let toolbarTimer = useRef(0);
	let highlightedElement = useRef(null);
	let audioRef = useRef(null);
	let audioTracking = useRef(null); //  [next tag, its starting time]
	let animationRunning = useRef(false);
	let animationRange = useRef(null);
	let hideTimer = useRef(null);
	let autoStart = useRef(false);

	let audioStateCopy = useRef(audioState);
	audioStateCopy.current = audioState;

	console.log("SfmViewer, animationRunning " + animationRunning.current);
	// console.log(nodeRef.current);
	// TODO: lower frequency of calling recent.update by tracking last history pushed
	// and only calling if chapter changes or verse changes by more than 1

	// wrap functions to limit unnecessary re-renders
	// otherwise they are redefined on each render

	// UTILITY FUNCTIONS USED FURTHER DOWN

	// fixBackground sets the background color of the overlay to be the same as menubar
	// overlay shows chapter:verse atop the "HeartLMD" menubar label
	const fixBackground = useCallback(() => {
		anchors.current = document.getElementsByClassName("HTcanOverlay");
		jumpToRef.current = document.getElementById("SfmJumpTo");
		var style;
		// typically 2 results are returned, one is real other isn't, detect by non-zero width
		let element =
			anchors.current[0].width > 0 ? anchors.current[0] : anchors.current[1];
		element = element.parentNode.parentNode.parentNode.parentNode;
		if (window.getComputedStyle) {
			style = window.getComputedStyle(element);
		} else {
			style = element.currentStyle;
		}
		if (style) {
			jumpToRef.current.style.backgroundColor = style.backgroundColor;
		}
	}, []);

	// trackToolbar moves the chapter:verse overlay to track the position of
	// the floating menubar, and even if scrolling stops or is stopped,
	// loop on tracking the AppBar until it stops so that to keep overlay in sync
	const clearToolbarTimer = () => {
		toolbarTimer.current = null;
	};
	const trackToolbar = useCallback(() => {
		if (!anchors.current) return;
		let rectangle = anchors.current[0].getBoundingClientRect();
		if (rectangle.width === 0) {
			// one or the other will be 0
			rectangle = anchors.current[1].getBoundingClientRect();
		}
		//console.log("anchors...");
		let top = rectangle.top;
		let left = rectangle.left;
		let width = rectangle.width;
		let jumpTo = jumpToRef.current;
		let prevTop = jumpTo.style.top;
		//console.log("window.innerWidth "+window.innerWidth);
		//console.log("new left "+left);
		jumpTo.style.top = top + "px";
		jumpTo.style.left = left + "px";
		jumpTo.style.width = width + "px";
		console.log("prevTop " + prevTop + ", top " + top + "px");
		if (prevTop !== top + "px") {
			jumpToTop.current = top; // re-render until toolbar has stopped:
			if (!toolbarTimer.current) clearTimeout(toolbarTimer);
			toolbarTimer.current = setTimeout(clearToolbarTimer, 500); // 500ms after last change
			setTimeout(trackToolbar, 10); // fast polling on change
			// console.log(" new top "+top);
		} else {
			if (toolbarTimer.current) setTimeout(trackToolbar, 20);
		}
	}, []);

	const resolveURL = useCallback((url) => {
		url = io.resolveService(url);
		console.log(url);
		return url;
	}, []);

	// after 8 seconds of playing, audio player hides
	// timer resets on every re-render, so only has an interesting
	// effect while listening to audio
	const delayHideAudio = useCallback(() => {
		if (audioShowing && audioRef.current && !audioRef.current.paused) {
			console.log("hide audio ");
			setAudioShowing(false);
		}
	}, [audioShowing]);
	if (audioState !== AudioStates.noData) {
		if (hideTimer.current) clearTimeout(hideTimer.current); // restart timer on every refresh
		hideTimer.current = setInterval(delayHideAudio, 8000); // hide audio after 8 sec of playing
	}

	// clickToTimingTag assists in onClick processing by finding
	// the span that was clicked (useful for Audio to jump phrases)
	const clickToTimingTag = useCallback((event) => {
		const x = event.clientX;
		const y = event.clientY;
		console.log("Clicked at x:", x, "y:", y);
		const closestElement = document.elementFromPoint(x, y);
		console.log("Closest element:", closestElement);
		// TODO: if this isn't a tagged span, walk nearby to find one?
		return closestElement.id;
	}, []);

	const divClick = useCallback(
		(event) => {
			console.log("divClick ");
			if (audioState === AudioStates.ready) {
				setAudioShowing(!audioShowing);
			}
			setTimeout(trackToolbar, 50); // delay for a repaint
			// setTimeout(trackToolbar, 200);
			// setTimeout(trackToolbar, 500); // do twice in case the first one caught it in transition
			animationRunning.current = false;
			clickToTimingTag(event); // just diagnostic for now
			//pauseAudio();
			//window.scrollTo({ top: window.scrollY - 60 });
		},
		[audioShowing, audioState, clickToTimingTag, trackToolbar]
	);

	const removeHighlight = useCallback(() => {
		const elem = highlightedElement.current;
		if (!elem) return;
		console.log("un highlight " + elem.id);
		elem.style.backgroundColor = elem.parentElement.style.backgroundColor;
		highlightedElement.current = null;
	}, []);

	const highlightSpan = useCallback(
		(v) => {
			if (!v) return;
			if (v.indexOf(":") > 0) v = syncAudio.verseToTimingTag(v, timings.current);
			const elem = document.getElementById(v);
			removeHighlight();
			console.log("highlight " + elem.id);
			elem.style.backgroundColor = "yellow";
			highlightedElement.current = elem;
		},
		[removeHighlight]
	);

	// use only verse formated id's for this search
	// usx files also have id's formatted as 'c-#' as peers of
	// paragraphs holding verses (could be used if following algorithm fails)
	const getChapterStart = useCallback((ic) => {
		let leading = ic + ":1";
		let e = document.getElementById(leading); // tagged #:# (2 numbers)
		if (!e) {
			for (let iv = 2; iv < 5; iv += 1) {
				e = document.getElementById(leading + "-" + iv); // tagged c:1-2, etc.
				if (e) break;
			}
			if (!e) {
				let chapters = document.getElementsByClassName("sfm-c"); // these have no id
				for (let i = 0; i < chapters.length; i += 1) {
					e = chapters[i].nextElementSibling; // sibling should be a verse with id="c:1 [1a...]"
					if (e.id.startsWith(leading)) break;
				}
			}
		}
		return e;
	}, []);

	// scroll the window so that the desired verse is near the top
	// uses chapter:verse div tags, but sometimes the tags are
	// for a range of verses, as in chapter:verse1-verse2
	const goToVerse = useCallback(
		(value) => {
			console.log("go to verse '" + value + "'");
			if (!value || value.indexOf(":") < 0) return;
			let ytarget = 0; // go to y=0 for verse 1:1
			if (value !== "1:1") {
				let e = document.getElementById(value);
				if (!e) {
					let [c, v] = value.split(":");
					e = getChapterStart(c);
					let vi = parseInt(v);
					let previous;
					if (e)
						do {
							if (e.id) {
								let parts = e.id.split(":");
								if (parts.length === 2) {
									if (parts[1] === v) break;
									if (parts[1].indexOf("-") > 0) {
										let vparts = parts[1].split("-");
										let v0 = parseInt(vparts[0]);
										let v1 = parseInt(vparts[1]);
										if (v0 <= vi && vi <= v1) break;
									}
								}
							}
							let previous = e;
							e = e.nextElementSibling;
							if (!e) {
								let ps = previous.parentElement.nextElementSibling;
								if (ps) e = ps.firstElementChild; // cousin
							}
						} while (e);
					if (!e || !e.id) e = previous; // if all else fails, go to chapter start
				}
				if (e) {
					ytarget = e.offsetTop - topOffset;
				} else {
					console.warn("no verse?"); // more options could be tried (some day)
					return;
				}
			}
			window.scrollTo({ top: ytarget });
			setVerse(value);
			nodeRef.current.verse = value;
			recent.update(nodeRef.current);
		},
		[getChapterStart]
	);

	// update the chapter:verse label over the Appbar, and update history
	// (called by the scroll handler)
	const updateVerse = useCallback(() => {
		// get verse at current top of scroll
		// TODO: instead of always doing an exhaustive search,
		// start from current verse, see where it is (y.offsetTop) and
		// search nearby siblings (better for slow scrolling, as when reading)
		let v;
		let e;
		let previous;
		let ic = 0;
		let y0 = window.scrollY + topOffset;
		let lastfound = "1:1";
		do {
			previous = e;
			ic++;
			e = getChapterStart(ic);
		} while (e && e.offsetTop < y0 && ic < nodeRef.current.ranges.length - 1);
		// start at previously found chapter start and walk forward by siblings & verses
		if (!previous) {
			v = "1:1"; // first chapter was > y0, set to verse 1:1
		} else {
			do {
				e = previous.nextElementSibling; // found chapter & verse 1, check later verses
				if (!e) {
					let elder = previous.parentElement.nextElementSibling;
					do {
						if (elder) e = elder.firstElementChild; // get cousin if no more siblings with id
						if (!e) elder = elder.nextElementSibling;
					} while (!e && elder);
				}
				if (!e) {
					console.warn("problem updating verse in chapter " + ic);
					break; // can't find this chapter start; TODO: check for more cousins
				}
				previous = e;
				if (e.id && e.id.indexOf(":") > 0) {
					lastfound = e.id;
				} else e = null; // not a verse, force loop to continue
			} while (!e || e.offsetTop < y0); // find first c:v or c:v-v tagged element with y higher than y0 (i.e visible)
			v = previous ? previous.id : null; // never undefined
			if (!v || v.indexOf(":") < 0) v = lastfound; // last found before exceeding y0 or failing to find another verse element
		}
		if (v) {
			let match = v.match(verseRegex);
			if (match) v = match[1] + match[2]; // removes extraneous [] and letters, captures first verse number
			setVerse(v); // triggers re-render so label is correct
			nodeRef.current.verse = v;
			recent.update(nodeRef.current); // update recently viewed // TODO: also update playing time
			history.replaceState({ ...history.state(), s: window.scrollY });
		}
		return v;
	}, [getChapterStart, history]);

	const closeVersePopup = (value) => {
		console.log("handleClose " + value);
		setVersePopupOpen(false);
		if (!value) return;
		if (audioRef.current && !audioRef.current.paused) {
			audioRef.current.pause();
			animationRunning.current = false;
		}
		goToVerse(value);
		if (audioState !== AudioStates.noData && audioState !== AudioStates.initialized)
			setAudioToVerse(value);
		handleMatomoCustomEvent("User Action", "Bible Navigate", "GoTo Verse " + value);
	};

	const setAudioToVerse = useCallback(
		(value) => {
			if (!value) {
				console.warn("no value to setAudioToVerse");
				return;
			}
			let tag = syncAudio.verseToTimingTag(value, timings.current);
			if (!tag) {
				console.warn("no tag from verseToTimingTag for " + value);
			}
			let startTime = syncAudio.timingTagToTime(tag, timings.current);

			audioTracking.current = [tag, startTime];
			autoStart.current = false;
			let chapter = tag.split("-")[0]; // split the timing tag to get the chapter
			let url = resolveURL(nodeRef.current.audio.replace("%", chapter));
			if (url !== audioURL) setAudioURL(url);
			else audioRef.current.currentTime = startTime;
			setAudioShowing(true);
			highlightSpan(tag); // highlight verse that will play when audio is ready (user can change it)
			if (audioState !== AudioStates.ready) {
				setAudioState(AudioStates.ready);
				history.replaceState({ ...history.state(), audio: AudioStates.ready });
			}
		},
		[audioState, audioURL, highlightSpan, history, resolveURL]
	);

	const trackScrollAudio = useCallback(
		(v) => {
			let ac = audioRef.current;
			console.log("track scroll for audio while paused " + ac?.paused);
			if (audioState === AudioStates.ready && ac?.paused) {
				console.log("scroll, audio " + ac?.paused);
				setAudioToVerse(v);
			}
		},
		[audioState, setAudioToVerse]
	);

	const unsetAudio = () => {
		console.log("unsetAudio");
		animationRunning.current = false;
		autoStart.current = false;
		audioRef.current.pause();
		setAudioState(AudioStates.initialized);
		history.replaceState({ ...history.state(), audio: AudioStates.initialized });
		setAudioShowing(false);
		removeHighlight(); // TODO: not correct, just for testing
	};

	const pauseAudio = useCallback(() => {
		if (audioRef.current && audioRef.current.paused) return;
		console.log("pauseAudio");
		audioRef.current.pause();
		animationRunning.current = false;
		autoStart.current = false;
		setAudioState(AudioStates.ready);
		setAudioShowing(true);
	}, []);

	const toggleAudio = (event) => {
		console.log("toggle");
		event.stopPropagation();
		if (audioState === AudioStates.initialized) {
			setAudioToVerse(verse); // get ready to play starting at verse state -> .ready
		} else if (audioState === AudioStates.ready && audioShowing) {
			unsetAudio();
		} else if (!audioShowing) {
			setAudioShowing(true);
		} else {
			pauseAudio(); // Play is done only through the audio controls except chapter chaining
		}
	};

	// USE EFFECT ROUTINES

	// DATA FETCH, INITIALIZATION
	useEffect(() => {
		// runs once after render to start fetching data
		try {
			// get referenced data, either from IndexedDB or from web (or web cache)
			let file = nodeRef.current.file;
			let noData = false;
			let syncData = nodeRef.current.sync;
			let mediaType = nodeRef.current.mediaType; // one of the sfm types
			nodeRef.current.ranges = getSfmRanges(nodeRef.current.shortName);
			io.getItem(file) // either from db or web
				.then(async (result) => {
					//console.log(result);
					if (result && !result.startsWith("<p")) {
						// if starts with "<p" then came from cache, else pre-process:
						noData =
							result.startsWith("offline") ||
							result.startsWith("Error") ||
							result.startsWith("ERROR") ||
							result.length < 200;
						if (mediaType && !noData) {
							if (mediaType === "sfm") result = sfmToHtml(result);
							else if (mediaType === "usx") result = usxToHtml(result);
							if (result.startsWith("Error")) noData = true;
						}
						if (syncData && !noData) {
							setTimeout(() => {
								syncAudio
									.annotateAudio(result, syncData)
									.then(async (annotated) => {
										// [html, timings]
										if (annotated && annotated.length === 2) {
											result = annotated[0];
											timings.current = annotated[1];
											console.log(
												"setting annotated data, length " +
													result.length
											);
											setData(result); // triggers a re-render with new data (might be error string)
											let timingJson = JSON.stringify(
												timings.current
											);
											setAudioState(AudioStates.initialized);
											await database.putToDB(
												"itemCache",
												file + "timings",
												timingJson
											);
										}
										database.putToDB("itemCache", file, result);
										setTimeout(setupTooltipAdjustments, 2000); // should be after next render
									});
							}, 10);
							// start background task to cache the converted file,
							// returns a promise quickly
						} else if (!noData)
							database.putToDB("itemCache", nodeRef.current.file, result);
					} else {
						if (result && result.indexOf('<span id="1-') > 0) {
							let temp = await database.getFromDB(
								"itemCache",
								file + "timings"
							);
							if (temp) {
								timings.current = JSON.parse(temp);
								console.log(
									"restored timings, length " + timings.current.length
								);
								console.log(timings.current);
								setAudioState(AudioStates.initialized);
							} else console.error("Error getting timings for " + file);
						} else console.log("restored html has no audio tags");
						setTimeout(setupTooltipAdjustments, 2000); // should be after next render
					}
					if (result && result.length < 200) {
						// short is probably an error message
						console.error("SfmViewer received bad data:");
						console.log(result);
					}
					console.log("setting initial data, length " + result.length);
					setData(result); // triggers a re-render with new data (might be error string)
					setTimeout(trackToolbar, 250);
					setDataReady(true);
				})
				.catch((error) => {
					console.log(
						"caught error in SfmViewer, status " +
							error.status +
							" " +
							error.message
					);
					console.log(error);
					setData(
						"<p style='margin:18px; color:DarkRed'>" + error.message + "</p"
					);
				});
		} catch (error) {
			console.error("Error thrown in SfmViewer " + error.message);
			setData("<p style='margin:18px; color:DarkRed'>" + error.message + "</p");
		}
		fixBackground(); // done after page has first rendered with no data
		if (!nodeRef.current.verse) {
			// bookkeeping for starting verse
			nodeRef.current.verse = "1:1";
			recent.update(nodeRef.current); // initial 'recently view' update even though not yet rendered
		}
	}, [props.node, fixBackground, trackToolbar]);

	// POST DATA FETCH & INITIALIZATION
	// runs twice, but has effect only once, after data has been rendered
	useEffect(() => {
		if (dataReady) {
			let verse = "1:1";
			if (nodeRef.current.verse && nodeRef.current.verse !== "1:1") {
				verse = nodeRef.current.verse;
				goToVerse(nodeRef.current.verse); // calling from useEffect delays this until render done!
			}
			if (initialAudio.current) setAudioToVerse(verse);
		}
	}, [dataReady, goToVerse, setAudioToVerse]);

	// Language tracking
	useEffect(() => {
		console.log("set up language listener");
		const handleLanguageChange = (lng) => {
			console.log("Language changed to:", lng);
			setTimeout(trackToolbar, 30); // wait until language change re-render finishes
			setTimeout(trackToolbar, 120); // do again in case something was slow
		};

		i18next.on("languageChanged", handleLanguageChange);

		// Clean up the event listener on component unmount
		return () => {
			i18next.off("languageChanged", handleLanguageChange);
		};
	}, [trackToolbar]);

	// SCROLL TRACKING (finds verse near top of page, updates the display)
	// and also tracks the AppBar, so that floating div stays above the moving AppBar
	useEffect(() => {
		var timer = null;
		const handleScroll = (event) => {
			if (!timer) timer = setTimeout(processScroll, 50); // at most 20x per second
		};
		const processScroll = () => {
			timer = null;
			trackToolbar();
			let v = updateVerse();
			if (v) trackScrollAudio(v);
		};
		setTimeout(trackToolbar, 50); // initial tracking
		//setTimeout(trackToolbar, 250); // once more in case it is still scrolling to starting verse
		window.addEventListener("scroll", handleScroll, { passive: true });
		window.onresize = (event) => {
			trackToolbar();
			//setTimeout(trackToolbar, 200);
			//setTimeout(setupTooltipAdjustments, 1000); // should be after next render
		};
		return () => {
			window.removeEventListener("scroll", handleScroll);
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [updateVerse, trackToolbar, trackScrollAudio]);

	// AUDIO SUPPORT, INCLUDING AUTO SCROLLING OF VERSES BEING READ
	useEffect(() => {
		// sets up audio play if available; must have audioState in dependencies
		if (audioState === AudioStates.noData) return; // run once after leaving noData state
		console.log("audio useEffect " + audioState);

		function smoothScroll(targetId, duration) {
			console.log("smoothScroll " + targetId + " " + duration);
			const targetElement = document.getElementById(targetId);
			if (!targetElement) return;
			const startingPosition = window.scrollY;
			const ih = window.innerHeight;
			const targetPosition = targetElement.offsetTop - ih / 2; // mid screen at play start;
			let distance = targetPosition - startingPosition;
			console.log("smooth " + startingPosition + " " + targetPosition);
			if (distance < 0) return;
			// if (distance > 0 && distance < ih * 0.1) return; // don't bother scrolling up 10%
			// if (distance < 0 && -distance < ih * 0.3) return; // reading will eventually reach center
			// if (distance < 0) distance = distance / 2; // move halfway, reading will do the rest
			let startTime = null;
			// let lastY = startingPosition;
			// let lastRun = lastY;
			duration *= 1000; // convert seconds to ms
			function animation(currentTime) {
				if (!animationRunning.current) {
					console.log("animationRunning.current false in animation");
					return;
				}
				if (animationRange.current) {
					let y = window.scrollY; // detect manual scroll and if so abandon animation
					let [y0, y1] = animationRange.current;
					if (y < y0 - 1 || y > y1 + 1) {
						console.log("abandon animation: out of range");
						animationRunning.current = false;
						animationRange.current = null;
						return;
					}
				}
				if (targetId !== audioTracking.current[0]) {
					console.log(
						"abandon " + targetId + " for " + audioTracking.current[0]
					);
					return; // this animation no longer needed
				} else console.log("same " + targetId);
				//let scrollY = window.scrollY;
				//if (scrollY < lastY || scrollY > lastRun) return; // user scrolled to get to menu; abandon this animation
				if (startTime === null) startTime = currentTime; // first frame, capture start time
				const timeElapsed = currentTime - startTime;
				const run = ease(timeElapsed, startingPosition, distance, duration);
				// console.log(
				// 	"elapsed " + timeElapsed + " of " + duration + "; top->" + run
				// );
				animationRange.current = [window.scrollY, run];
				window.scrollTo({ top: run });
				// lastY = scrollY;
				if (timeElapsed < duration) requestAnimationFrame(animation); // loop until done
			}
			function ease(t, b, c, d) {
				t /= d / 2;
				if (t < 1) return (c / 2) * t * t + b; // quadratic acceleration
				t--;
				return (-c / 2) * (t * (t - 2) - 1) + b; // deceleration
			}
			if (animationRunning.current) requestAnimationFrame(animation);
		}

		function loadedMetadata() {
			console.log("loaded metadata");
			// at metadata load, jump to desired time (from node.time)
			let tracker = audioTracking.current;
			if (tracker && tracker[1]) {
				// is tracking time > 0? data from timestamps or history
				audioRef.current.currentTime = tracker[1];
				console.log("tracking " + tracker[0] + " @ " + tracker[1]);
			}
		}
		function canPlay() {
			//audio is ready to play
			console.log("audio can play, paused = " + audioRef.current.paused);
			// if (audioTracking.current && audioTracking.current.length > 1) {
			// 	console.log(
			// 		"tracking " +
			// 			audioTracking.current[0] +
			// 			" @ " +
			// 			audioTracking.current[1]
			// 	);
			// }
			// console.log("play started");
		}
		function canPlayThrough() {
			//audio is ready to play all the way through
			console.log("can play through, paused " + audioRef.current.paused);
			if (autoStart.current) audioRef.current.play();
		}
		function playStarting() {
			let elapsed = audioRef.current.currentTime;
			console.log("playing " + highlightedElement.current.id + " at " + elapsed);
			audioTracking.current = syncAudio.getNextTagChange(
				audioTracking.current[0],
				elapsed,
				timings.current
			);
			console.log(
				"next: " + audioTracking.current[0] + " @ " + audioTracking.current[1]
			);
			animationRunning.current = true;
			smoothScroll(audioTracking.current[0], 1000); // Scroll first element to midscreen if below for 1 second
		}
		function playPaused(event) {
			console.log("play paused");
			animationRunning.current = false;
		}
		function playEnded(event) {
			console.log("play ended (for this chapter)");
		}
		function timeUpdate(event) {
			let elapsed = audioRef.current.currentTime;
			// console.log(elapsed);
			if (elapsed > audioTracking.current[1]) {
				highlightSpan(audioTracking.current[0]);
				history.replaceState({ ...history.state(), time: elapsed }); // once per phrase (verse is in state already)
				if (audioTracking.current.length === 3) {
					audioTracking.current[1] = audioTracking.current[2]; // time to start in new clip is in [2]
					audioTracking.current.pop();
					nextChapter(); // should auto restart at canplay?
				} else {
					const lastStart = audioTracking.current[1];
					audioTracking.current = syncAudio.getNextTagChange(
						audioTracking.current[0],
						elapsed,
						timings.current
					);
					if (!audioTracking.current[0]) return; // no more tags, end of book
					let start = audioTracking.current[1];
					let duration = start - lastStart;
					if (duration > 0 && animationRunning.current)
						smoothScroll(audioTracking.current[0], duration); // scroll next phrase up while current is playing
					if (!animationRunning.current)
						setTimeout(() => {
							if (audioRef.current && !audioRef.current.paused)
								animationRunning.current = true;
						}, 3000); // this restarts animation in 3 seconds if it was paused
				}
				console.log(audioTracking.current);
			}
		}
		function nextChapter() {
			console.log("audio ended");
			if (!audioTracking.current || !audioTracking.current[0]) return;
			let url = nodeRef.current.audio;
			let nextChapter = audioTracking.current[0].split("-")[0];
			url = url.replace("%", nextChapter);
			url = io.resolveService(url);
			console.log("... chaining to " + url);
			autoStart.current = true;
			setAudioURL(url);
			animationRunning.current = false; // abort animation until next chapter reaches loadedmetadata
		}

		function removeEventListeners(ap) {
			let options = { passive: true }; // capture for the unmount closure below
			ap.removeEventListener("loadedmetadata", loadedMetadata, options);
			ap.removeEventListener("canplay", canPlay, options);
			ap.removeEventListener("canplaythrough", canPlayThrough, options);
			ap.removeEventListener("playing", playStarting, options);
			ap.removeEventListener("pause", playPaused, options);
			ap.removeEventListener("timeupdate", timeUpdate, options);
			ap.removeEventListener("ended", playEnded, options);
		}

		let audioPlayer = audioRef.current;

		try {
			removeEventListeners(audioPlayer);
			audioPlayer.addEventListener("loadedmetadata", loadedMetadata);
			audioPlayer.addEventListener("canplay", canPlay);
			audioPlayer.addEventListener("canplaythrough", canPlayThrough);
			audioPlayer.addEventListener("playing", playStarting);
			audioPlayer.addEventListener("pause", playPaused);
			audioPlayer.addEventListener("timeupdate", timeUpdate);
			audioPlayer.addEventListener("ended", playEnded);
			audioPlayer.load(); // TODO: audio.load() is only done once, maybe need more at later chapters?
		} catch (error) {
			console.error(error.message);
			setAudioURL("");
		}
		return () => {
			removeEventListeners(audioPlayer);
		};
	}, [audioState, highlightSpan, history]); // make sure no depencies can change during playback

	const LightTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: theme.palette.common.white,
			color: "rgba(0, 0, 0, 0.87)",
			boxShadow: theme.shadows[1],
			fontSize: 11
		}
	}));
	const getTooltipText = () => {
		return audioShowing ? "Disable Audio Mode" : "Enable Audio Mode";
	};
	//trackToolbar(); // if anything caused a repaint, make sure overlays are in the right place
	// may need to add a delayed one, or more this to a useEffect

	return (
		<div {...props} ref={ref}>
			<div
				className="overlayHT"
				id="SfmJumpTo"
				style={{
					position: "sticky",
					top: "0px",
					backgroundColor: "primary.main"
				}}
			>
				<Grid container wrap="nowrap" sx={{ minWidth: 0, width: "auto" }}>
					<Button
						variant="dense"
						sx={{
							mr: 1.2,
							px: 0.4,
							py: 0.5,
							minWidth: 0,
							width: "auto",
							backgroundColor: "white",
							whiteSpace: "nowrap"
						}}
						onClick={(e) => {
							setVersePopupOpen(true);
							e.stopPropagation();
						}}
					>
						{nodeRef.current.shortName + " " + verse}
					</Button>
					<LightTooltip title={getTooltipText()}>
						<IconButton
							color="inherit"
							onClick={toggleAudio}
							sx={{ p: 0.2 }}
						>
							{audioState !== AudioStates.noData && !audioShowing && (
								<VolumeUpIcon
									sx={{
										color: "white",
										display: "block",
										width: "28px",
										height: "28px"
									}}
								/>
							)}
							{audioState !== AudioStates.noData && audioShowing && (
								<VolumeOffIcon
									sx={{
										color: "white",
										display: "block",
										width: "28px",
										height: "28px"
									}}
								/>
							)}
						</IconButton>
					</LightTooltip>
				</Grid>
			</div>
			{nodeRef.current.ranges && ( // add only after node has valid data
				<SelectionDialog
					node={nodeRef.current}
					open={versePopupOpen}
					onClose={closeVersePopup}
				/>
			)}
			<Box sx={{ width: "100%", py: 4, px: 2, overflow: "hidden" }}>
				{" "}
				{/* smaller padding since floating div above consumes space */}
				<div
					id="sfm-contents"
					dangerouslySetInnerHTML={{ __html: data }}
					onClick={divClick}
				/>
			</Box>
			<Slide direction="up" in={audioShowing}>
				<Paper
					elevation={1}
					style={{
						position: "fixed",
						bottom: 0,
						left: 0,
						right: 0,
						height: "40px",
						padding: "0px",
						margin: "0px",
						opacity: 0.85
					}}
				>
					<Box
						sx={{
							bgcolor: "rgb(163, 203, 245)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%" // Or any specific width
						}}
					>
						<audio
							ref={audioRef}
							controls
							src={audioURL}
							style={{ height: "40px", padding: "5px" }}
						>
							{/* <audiosource
								id="sfmAudioSource"
								src={
									"http://localhost:8000/testFiles/YBZ/01GENaudio/1.mp3"
								}
							></audiosource> */}
						</audio>
					</Box>
				</Paper>
			</Slide>
		</div>
	);
});

export default React.memo(SfmViewer);
