import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import { makeStyles } from "@mui/styles";

function SelectionDialog(props) {
	let node = props.node;
	let open = props.open;
	let close = props.onClose;
	const [chapter, setChapter] = React.useState(0);

	//console.log("SelDia");
	const handleClose = (e) => {
		e.preventDefault();
		close(null);
	};

	const handleItemClick = (value) => {
		//console.log("handleItemClick "+value);
		if (chapter === 0) setChapter(value);
		else {
			setChapter(0); // set back to step 1 for next popup
			close(chapter + ":" + value);
		}
	};

	function getTitle() {
		if (chapter === 0) return "Select Chapter";
		return "Select Verse";
	}
	function getLabels() {
		let array = [];
		let ranges = node.ranges;
		if (chapter === 0)
			array = Array.from({ length: ranges.length }, (x, i) => i + 1);
		else array = Array.from({ length: ranges[chapter - 1] }, (x, i) => i + 1);
		//console.log(array);
		return array;
	}
	// const useStyles = makeStyles(
	//   {
	//     topScrollPaper: {
	//       alignItems: "flex-start"
	//     },
	//     topPaperScrollBody: {
	//       verticalAlign: "top"
	//     }
	//   }
	// );
	// const myClasses = useStyles();
	//  sx={{px:1, pb:0.5, m:0, width:120, whiteSpace:"nowrap",

	return (
		<Dialog onClose={handleClose} open={open} sx={{ minWidth: 160 }}>
			{/* <Dialog onClose={handleClose} open={open} scroll='paper' 
          classes={{scrollPaper: myClasses.topScrollPaper, paperScrollBody: myClasses.topPaperScrollBody}}> */}
			<DialogTitle
				sx={{
					m: 0,
					px: 1,
					py: 0.2,
					minWidth: 160,
					whiteSpace: "nowrap"
				}}
			>
				{getTitle()}
			</DialogTitle>
			<Grid container spacing={0} sx={{ px: 1 }} columns={{ xs: 10 }}>
				{getLabels().map((label, index) => (
					<Grid item key={label} xs={1} style={{ minWidth: "26px" }}>
						<Box
							onClick={() => handleItemClick(label)}
							sx={{
								width: 12,
								p: 0.1,
								m: 0.1,
								textAlign: "center",
								"&:hover": { cursor: "pointer" }
							}}
						>
							{index + 1}
						</Box>
					</Grid>
				))}
			</Grid>
		</Dialog>
	);
}

export default SelectionDialog;
